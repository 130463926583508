/* eslint-disable no-undef */
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Menu, MenuItem, Slide, Snackbar, styled} from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { importMerchants } from '../../services/merchant';
import Merchants from './Merchants';
import { merchantsSampleFile, notesSampleFile, reservesSampleFile } from '../../utils/constants';
import { UploadModal } from '../../components';

const Wrapper = styled('div')({
  padding: 35,
  fontSize: 'calc(10px + 2vmin)',
});

const Home = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [merchantLoading, setMerchantLoading] = useState(false);
  const [agentLoading, setAgentLoading] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [reservesLoading, setReservesLoading] = useState(false);
  const [tsysLoading, setTsysLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [severity, setSeverity] = useState('');
  const [updatedKey, setUpdatedKey] = useState(1);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const inputRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onClickHandler = async (fileType) => {
    if (selectedFile) {
      const data = new FormData();
      data.append('file', selectedFile);
      data.append('type', fileType);
      if (fileType === 'merchant') {
        setMerchantLoading(true);
      } else if (fileType === 'notes') {
        setNotesLoading(true);
      } else if (fileType === 'reserve') {
        setReservesLoading(true);
      } else if (fileType === 'agents') {
        setAgentLoading(true); }
        else {
        setTsysLoading(true);
      }
      const response = await importMerchants(data);
      if (response.data.success) {
        setSeverity('success');
        if (fileType === 'merchant') {
          triggerReload();
        }
      } else {
        setSeverity('error');
      }
      inputRef.current.value = null;
      setSelectedFile(null);
      setMerchantLoading(false);
      setAgentLoading(false);
      setNotesLoading(false);
      setReservesLoading(false);
      setTsysLoading(false);
      setAlert(true);
      setAlertContent(response.data.message);
      setOpenUploadModal(false);
    } else {
      setAlert(true);
      setSeverity('info');
      setAlertContent('Please select file!');
    }
  };

  const addMerchantHandler = () => {
    navigate('/addMerchant');
  };

  const triggerReload = () => {
    let newKey = Math.floor(Math.random() * 100); // make sure the key are never the same
    setUpdatedKey(newKey);
  };

  return (
    <Wrapper className='App'>
      {alert ? (
        <Snackbar
          open={alert}
          autoHideDuration={6000}
          onClose={() => {
            setAlert(false);
          }}
          TransitionComponent={Slide}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlert(false);
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertContent}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}

      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 1,
          marginTop: 2,
          marginBottom: 3,
        }}
      >
        <div style={{ marginLeft: 'auto' }}>
          <Button
            variant='contained'
            sx={{ margin: 1, textTransform: 'none'}}
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            Download Samples
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>
              <a
                href={`${process.env.REACT_APP_SAMPLE_FILES_BUCKET}${merchantsSampleFile}`}
                rel='noreferrer'
                style={{ textDecoration: 'none', color: 'black' }}
              >
                Merchant Sample
              </a>
            </MenuItem>
            <hr style={{ width: '100%' }}></hr>

            <MenuItem onClick={handleClose}>
              <a
                href={`${process.env.REACT_APP_SAMPLE_FILES_BUCKET}${notesSampleFile}`}
                rel='noreferrer'
                style={{ textDecoration: 'none', color: 'black' }}
              >
                Notes Sample
              </a>
            </MenuItem>
            <hr style={{ width: '100%' }}></hr>

            <MenuItem onClick={handleClose}>
              <a
                href={`${process.env.REACT_APP_SAMPLE_FILES_BUCKET}${reservesSampleFile}`}
                rel='noreferrer'
                style={{ textDecoration: 'none', color: 'black' }}
              >
                Reserves Sample
              </a>
            </MenuItem>
          </Menu>
          <Button
            variant='contained'
            sx={{ margin: 1, textTransform: 'none' }}
            onClick={() => setOpenUploadModal(true)}
          >
            Bulk Import
          </Button>
          <Button
            variant='contained'
            sx={{ margin: 1, textTransform: 'none'}}
            onClick={addMerchantHandler}
          >
            Add Merchant
          </Button>
        </div>
      </Box>
      <Merchants key={updatedKey} />
      <UploadModal
        open={openUploadModal}
        handleClose={() => setOpenUploadModal(false)}
        onChangeHandler={onChangeHandler}
        inputRef={inputRef}
        onClickHandler={onClickHandler}
        merchantLoading={merchantLoading}
        agentLoading = {agentLoading}
        notesLoading={notesLoading}
        reservesLoading={reservesLoading}
        tsysLoading={tsysLoading}
      />
    </Wrapper>
  );
};

export default Home;

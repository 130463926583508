import React, { useState } from 'react';
import './index.css';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const CustomUploader = ({ onChangeHandler, inputRef, isPdfUpload }) => {
  const [fileNames, setFileNames] = useState([]);

  const getSelectedFileNames = (files) => {
    let fileNames = [];
    for (const file of files) {
      fileNames.push(file.name);
    }
    setFileNames(fileNames);
  };
  return (
    <>
      <h3>Upload Files</h3>
      <div className='drop_box '>
        <header>
          <h4>Select File here</h4>
        </header>
        <p> Files Supported: {isPdfUpload ? 'PDF' : 'CSV'}, Max Size: 20MB</p>
        <Typography
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            fontSize: 18,
            fontWeight: '700',
          }}
        >
          {fileNames.join(', ')}
        </Typography>
        <div
          className='file-upload-wrapper'
          data-text='Select your file.'
          style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
        >
          <input
            type='file'
            name='file'
            multiple={isPdfUpload}
            accept={isPdfUpload ? '.pdf' : '.csv'}
            id='fileID'
            onChange={(e) => {
              onChangeHandler(e);
              getSelectedFileNames(e.target.files);
            }}
            ref={inputRef}
            className='input-class file-upload-field '
          />
        </div>
      </div>
    </>
  );
};

CustomUploader.propTypes = {
  onChangeHandler: PropTypes.func,
  inputRef: PropTypes.any,
  isPdfUpload: PropTypes.bool,
};

export default CustomUploader;

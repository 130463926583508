import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  styled,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Alert,
  Grid,
  Snackbar,
  Slide,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { headCellsNotes, headCellsPdfs, headCellsReserve } from '../../utils/tableData';
import { addPDFRecord, getMerchantById, UploadPDF } from '../../services/merchant';
import { EnhancedTable, UploadModal } from '../../components';
import { addNotes, editNotes } from '../../services/notes';
import ReserveNotes from './ReserveNotes';
import MerchantDetails from './MerchantDetails';

const Wrapper = styled('div')({
  padding: 35,
  fontSize: 'calc(10px + 2vmin)',
});
const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Notes = () => {
  const notesType = ['Shared Note', 'UW Notes', 'Risk Note', 'Reserve', 'PDF'];
  const [value, setValue] = useState(0);
  const [sharedNotes, setsharedNotes] = useState([]);
  const [uWNotes, setuWNotes] = useState([]);
  const [riskNotes, setriskNotes] = useState([]);
  const [reserveNotes, setReserveNotes] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [merchantLoading, setMerchantLoading] = useState(false);
  const [notesData, setNotesData] = useState({});
  const [merchant, setMerchant] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [severity, setSeverity] = useState('');
  const [note, setNote] = useState('');
  const [newNote, setNewNote] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState({});
  const [pdfLoading, setPdfLoading] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const user = JSON.parse(localStorage.getItem('user'));

  const { state } = useLocation();
  const { MID } = state;

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => {
    setNote('');
    setOpen(false);
    setNewNote(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
  };

  useEffect(() => {
    getMerchantByIdHandler();
  }, []);

  const openModal = (note, date, typeNote, id) => {
    setNote(note);
    setNotesData({ note: note, date: date, typeNote: typeNote, id: id });
    handleModalOpen();
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const getMerchantByIdHandler = async () => {
    setMerchantLoading(true);
    const response = await getMerchantById(MID);
    if (response.data.success) {
      let uWNotes = [];
      let sharedNotes = [];
      let riskNotes = [];
      if (response.data.result[0]) {
        setMerchant(response.data.result[0]);
        setReserveNotes(response.data.result[0].reserves || []);
        setPdfs(response.data.result[0].pdfs || []);
      }
      response.data.result[0].notes &&
        response.data.result[0].notes.forEach((e) => {
          if (e.typeNote === 'Shared Note') {
            sharedNotes.push(e);
          } else if (e.typeNote === 'Risk Note') {
            riskNotes.push(e);
          } else if (e.typeNote === 'UW Notes') {
            uWNotes.push(e);
          }
        });
      setuWNotes(uWNotes);
      setsharedNotes(sharedNotes);
      setriskNotes(riskNotes);
    }
    setMerchantLoading(false);
  };

  const onSubmitHandler = async () => {
    try {
      if (note) {
        let data = {
          note: note,
          dBAName: merchant.dBAName,
          mID: merchant.merchantID,
          username: newNote ? `${user?.firstName} ${user?.lastName}` : merchant.primaryOwnerName,
          typeNote: notesData.typeNote || notesType[value],
          id: notesData.id || '',
          date: notesData.date || new Date(),
        };
        let response = {};
        setIsLoading(true);
        if (notesData.id) {
          response = await editNotes(data);
        } else {
          response = await addNotes(data);
        }
        setIsLoading(false);

        if (response.data.success) {
          handleModalClose();
          setSeverity('success');
          getMerchantByIdHandler();
        } else {
          setSeverity('error');
        }
        setAlert(true);
        setAlertContent(response.data.message);
      } else {
        handleModalClose();
      }
    } catch (e) {
      console.error(e);
    }
  };
  const addNoteHandler = () => {
    if (value === 4) {
      setOpenUploadModal(true);
    } else {
      setNewNote(true);
      setNote('');
      setNotesData({});
      handleModalOpen();
    }
  };

  const handleChangeNote = (e) => {
    setNote(e.target.value);
  };
  const handleModalCloseReserve = (type) => {
    if (type === 'success') {
      handleModalClose();
      getMerchantByIdHandler();
    }
  };

  const onChangeHandler = (event) => {
    setSelectedPdf(event.target.files);
  };

  const onUploadClick = async () => {
    try {
      if (selectedPdf.length > 0) {
        const data = new FormData();
        for (const file of selectedPdf) {
          data.append('file', file);
        }

        setPdfLoading(true);
        const response = await UploadPDF(data);

        if (response.data.success) {
          let addPDFRecordResponse = '';
          for (const item of response.data.result) {
            const addPDFRecordPayload = {
              merchantID: merchant.merchantID,
              fileName: item.originalname,
              key: item.key,
              username: user.firstName + ' ' + user.lastName,
            };
            addPDFRecordResponse = await addPDFRecord(addPDFRecordPayload);
          }
          if (addPDFRecordResponse.data.success) {
            setSeverity('success');
            getMerchantByIdHandler();
          } else {
            setSeverity('error');
          }
        } else {
          setSeverity('error');
        }
        inputRef.current.value = null;
        setSelectedPdf({});
        setPdfLoading(false);
        setAlert(true);
        setAlertContent(response.data.message);
        setOpenUploadModal(false);
      } else {
        setAlert(true);
        setSeverity('info');
        setAlertContent('Please select file!');
      }
    } catch (error) {
      setSeverity('error');
      setPdfLoading(false);
    }
  };

  return (
    <Wrapper>
      {alert ? (
        <Snackbar
          open={alert}
          autoHideDuration={6000}
          onClose={() => {
            setAlert(false);
          }}
          TransitionComponent={Slide}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            severity={severity}
            style={{ marginBottom: '16px' }}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlert(false);
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            {alertContent}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexDirection: 'row',
        }}
      >
        <Button
          variant='contained'
          sx={{
            width: '5%',
            height: 35,
          }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Button
          onClick={addNoteHandler}
          variant='contained'
          style={{ marginLeft: 'auto', display: 'block' }}
        >
          Add {notesType[value]}
        </Button>
      </Box>
      <Grid item container xs={12} sm={12}>
        <Grid item xs={12} sm={9}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
              <Tab label='Shared Notes' {...a11yProps(0)} />
              <Tab label='UW Notes' {...a11yProps(1)} />
              <Tab label='Risk Notes' {...a11yProps(2)} />
              <Tab label='Reserves' {...a11yProps(3)} />
              <Tab label='PDFs' {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <EnhancedTable
              headCells={headCellsNotes}
              rows={sharedNotes}
              enableActions={false}
              openModal={openModal}
              type={'notes'}
              page = {page}
              setPage = {setPage}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EnhancedTable
              headCells={headCellsNotes}
              rows={uWNotes}
              enableActions={false}
              openModal={openModal}
              type={'notes'}
              page = {page}
              setPage = {setPage}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EnhancedTable
              headCells={headCellsNotes}
              rows={riskNotes}
              enableActions={false}
              type={'notes'}
              openModal={openModal}
              page = {page}
              setPage = {setPage}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <EnhancedTable
              headCells={headCellsReserve}
              rows={reserveNotes}
              enableActions={false}
              type={'reserves'}
              page = {page}
              setPage = {setPage}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <EnhancedTable
              headCells={headCellsPdfs}
              rows={pdfs}
              enableActions={false}
              type={'pdfs'}
              page = {page}
              setPage = {setPage}
            />
          </TabPanel>
        </Grid>
        <Grid item xs={12} sm={3}>
          <MerchantDetails merchant={merchant} isLoading={merchantLoading} />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        {value !== 3 ? (
          <Box sx={Modalstyle}>
            <Typography variant='h5' fontWeight={'bold'} marginBottom={2}>
              {notesData.typeNote}
            </Typography>
            {newNote ? (
              <TextField
                placeholder='Write note here...'
                multiline
                rows={6}
                type='text'
                style={{ width: '100%' }}
                onChange={(e) => handleChangeNote(e)}
              />
            ) : (
              <div
                style={{
                  height: '60%',
                  border: 1,
                  borderRadius: 10,
                  borderStyle: 'solid',
                  padding: 10,
                  overflow: 'auto',
                }}
              >
                <Typography>{note}</Typography>
              </div>
            )}
            <Grid item container xs={12} sm={12} sx={{ marginTop: 3 }}>
              <Grid item xs={6} sm={6}>
                {newNote && (
                  <Button
                    variant='contained'
                    sx={{
                      margin: 1,
                      width: '80%',
                      height: 35,
                      display: 'block',
                      marginLeft: 'auto',
                    }}
                    onClick={onSubmitHandler}
                  >
                    {isLoading ? <CircularProgress style={{ color: 'white' }} /> : 'Save'}
                  </Button>
                )}
              </Grid>
              <Grid item xs={6} sm={6}>
                <Button
                  variant='contained'
                  sx={{
                    margin: 1,
                    width: '80%',
                    height: 35,
                    display: 'block',
                    marginLeft: 'auto',
                  }}
                  onClick={() => {
                    setOpen(false);
                    setNewNote(false);
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <ReserveNotes handleModalClose={handleModalCloseReserve} mID={merchant.merchantID} />
        )}
      </Modal>
      <UploadModal
        open={openUploadModal}
        handleClose={() => setOpenUploadModal(false)}
        isPdfUpload={true}
        onChangeHandler={onChangeHandler}
        onClickHandler={onUploadClick}
        isLoading={pdfLoading}
        inputRef={inputRef}
      />
    </Wrapper>
  );
};

TabPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
};

export default Notes;

import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Modal, Box, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import CustomUploader from '../CustomUploader';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  height: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const UploadModal = ({
  open,
  handleClose,
  onChangeHandler,
  inputRef,
  onClickHandler,
  merchantLoading,
  agentLoading,
  notesLoading,
  reservesLoading,
  tsysLoading,
  isPdfUpload = false,
  isLoading,
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Close
          style={{ position: 'absolute', right: 50, top: 30, cursor: 'pointer' }}
          onClick={handleClose}
        />
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {/* <input type='file' name='file' onChange={onChangeHandler} ref={inputRef} /> */}
          <CustomUploader
            onChangeHandler={onChangeHandler}
            inputRef={inputRef}
            isPdfUpload={isPdfUpload}
          />

          {isPdfUpload ? (
            <Button
              variant='contained'
              sx={{ margin: 1, textTransform: 'none' }}
              onClick={onClickHandler}
            >
              {isLoading ? <CircularProgress style={{ color: 'white' }} /> : 'Upload PDF'}
            </Button>
          ) : (
            <div style={{}}>
              <Button
                variant='contained'
                sx={{ margin: 1, textTransform: 'none' }}
                onClick={() => onClickHandler('merchant')}
              >
                {merchantLoading ? (
                  <CircularProgress style={{ color: 'white' }} />
                ) : (
                  'Upload Merchants'
                )}
              </Button>
              <Button
                variant='contained'
                sx={{ margin: 1, textTransform: 'none' }}
                onClick={() => onClickHandler('agents')}
              >
                {agentLoading ? (
                  <CircularProgress style={{ color: 'white' }} />
                ) : (
                  'Upload Agents'
                )}
              </Button>
              <Button
                variant='contained'
                sx={{ margin: 1, textTransform: 'none' }}
                onClick={() => onClickHandler('notes')}
              >
                {notesLoading ? <CircularProgress style={{ color: 'white' }} /> : 'Upload Notes'}
              </Button>
              <Button
                variant='contained'
                sx={{ margin: 1, textTransform: 'none' }}
                onClick={() => onClickHandler('reserve')}
              >
                {reservesLoading ? (
                  <CircularProgress style={{ color: 'white' }} />
                ) : (
                  'Upload Reserves'
                )}
              </Button>
              <Button
                variant='contained'
                sx={{ margin: 1, textTransform: 'none' }}
                onClick={() => onClickHandler('')}
              >
                {tsysLoading ? <CircularProgress style={{ color: 'white' }} /> : 'Upload RFF'}
              </Button>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

UploadModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onChangeHandler: PropTypes.func,
  inputRef: PropTypes.any,
  onClickHandler: PropTypes.func,
  merchantLoading: PropTypes.bool,
  agentLoading : PropTypes.bool,
  notesLoading: PropTypes.bool,
  reservesLoading: PropTypes.bool,
  tsysLoading: PropTypes.bool,
  isPdfUpload: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default UploadModal;

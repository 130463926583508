export const rows = [
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
  {
    id: 1,
    name: 'TSSC',
    block: 'A',
    plotNo: 5,
    paymentStatus: 'Regular',
    area: '3,713',
    office: 'Site Office',
    type: 'Residential',
    status: 'Available',
  },
];

export const headCellsMerchants = [
  {
    id: 'dBAName',
    numeric: false,
    disablePadding: true,
    label: 'DBA',
    show: true,
    wrapText: true
  },
  {
    id: 'merchantID',
    numeric: false,
    disablePadding: false,
    label: 'MID',
    show: true,
  },
  {
    id: 'legalAddressLine1',
    numeric: false,
    disablePadding: true,
    label: 'Legal',
    show: true,
    wrapText: true
  },
  {
    id: 'federalTaxID',
    numeric: false,
    disablePadding: false,
    label: 'EIN',
    show: true,
  },
  {
    id: 'secondaryOwnerName',
    numeric: true,
    disablePadding: true,
    label: 'Agent',
    show: true,
    wrapText: true
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    show: true,
  },
  {
    id: 'dateOpened',
    numeric: false,
    disablePadding: false,
    label: 'Open Date',
    show: true,
  },
  {
    id: 'actions',
    disablePadding: false,
    label: 'Actions',
    show: false,
  },
];
export const headCellsNotes = [
  {
    id: 'note',
    numeric: false,
    disablePadding: false,
    label: 'Note',
    show: true,
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    show: true,
  },
  {
    id: 'dBAName',
    numeric: false,
    disablePadding: false,
    label: 'User',
    show: true,
  },
];
export const headCellsReserve = [
  {
    id: 'activityDate',
    numeric: false,
    disablePadding: false,
    label: 'Activity Date',
    show: true,
  },
  {
    id: 'departmentName',
    numeric: false,
    disablePadding: false,
    label: 'Department Name',
    show: true,
  },
  {
    id: 'recordType',
    numeric: false,
    disablePadding: false,
    label: 'Record Type',
    show: true,
  },
  {
    id: 'rvs/RelAmt',
    numeric: false,
    disablePadding: false,
    label: 'Rvs/Rel Amt',
    show: true,
  },
  {
    id: 'rvs/RelPerc',
    numeric: false,
    disablePadding: false,
    label: 'Rvs/Rel Perc',
    show: true,
  },
  {
    id: 'grossSalesAmt',
    numeric: false,
    disablePadding: false,
    label: 'Gross Sales Amt',
    show: true,
  },
  {
    id: 'netACHAmt',
    numeric: false,
    disablePadding: false,
    label: 'NetACH Amt',
    show: true,
  },
  {
    id: 'activityAmt',
    numeric: false,
    disablePadding: false,
    label: 'Activity Amt',
    show: true,
  },
  {
    id: 'reserveBalance',
    numeric: false,
    disablePadding: false,
    label: 'Reserve Balance',
    show: true,
  },
];

export const headCellsPdfs = [
  {
    id: 'filename',
    numeric: false,
    disablePadding: false,
    label: 'FileName',
    show: true,
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    show: true,
  },
  {
    id: 'dBAName',
    numeric: false,
    disablePadding: false,
    label: 'User',
    show: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    show: true,
  },
];

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, styled, TextField, Button, CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import { loginUser } from '../../services/user';
import Logo from '../../assets/icons/logo-black.png';

const Wrapper = styled('div')({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'calc(10px + 2vmin)',
});

const LoginFormWrapper = styled('div')((props) => ({
  backgroundColor: 'white',
  borderRadius: 5,
  // height: 300,
  width: 500,
  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const Form = styled('form')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 10,
  paddingTop: 10,
  width: '100%',
});

function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  useEffect(() => {
    const userData = localStorage.getItem('user');
    userData && userData !== null && navigate('/');
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await loginUser(data);
      setIsLoading(false);
      if (!response.data.success) {
        setAlert(true);
        setAlertContent(response.data.message);
      } else {
        localStorage.setItem('user', JSON.stringify(response?.data?.result));
        navigate('/');
      }
    } catch (e) {
      setIsLoading(false);
      setAlert(true);
      setAlertContent('Something went wrong!');
    }
  };

  return (
    <Wrapper>
      {alert ? <Alert severity='error'>{alertContent}</Alert> : <></>}
      <img
        src={Logo}
        alt='Logo'
        style={{
          height: '20%',
          width: '20%',
          objectFit: 'contain',
        }}
      />
      <LoginFormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id='userName'
            label='Username'
            variant='outlined'
            sx={{ width: '100%' }}
            {...register('username', { required: true })}
            type='text'
            error={!!errors?.username}
            helperText={`${errors?.username ? 'User Name is required' : ''}`}
          />

          <Box sx={{ margin: 3, width: '100%' }}>
            <TextField
              id='password'
              label='Password'
              variant='outlined'
              sx={{ width: '100%' }}
              {...register('password', { required: true })}
              type='password'
              error={!!errors?.password}
              helperText={`${errors?.password ? 'Password is required' : ''}`}
            />
          </Box>
          <Button type='submit' variant='contained' sx={{ margin: 1, width: '100%', height: 55 }}>
            {isLoading ? <CircularProgress style={{ color: 'white' }} /> : 'Login'}
          </Button>
        </Form>
      </LoginFormWrapper>
    </Wrapper>
  );
}

export default Login;

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { Controller } from 'react-hook-form';

const UpdateMerchantCustomSelect = ({
  inputLabel,
  name,
  // eslint-disable-next-line no-unused-vars
  label,
  items,
  labelId,
  id,
  value,
  control,
  required,
}) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl required={required}>
        <InputLabel id={labelId}>{inputLabel}</InputLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={value || ''}
          render={({ field }) => (
            <Select
              style={{
                height: '35px',
                width : '120px',
                textAlign: 'center',
                backgroundColor: field.value === 'Open' ? '#4BB543 ' : '#ED4337',
                color: 'white',
              }}
              labelId={labelId}
              id={id}
              {...field}
            >
              {items?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </Box>
  );
};

UpdateMerchantCustomSelect.propTypes = {
  inputLabel: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  items: PropTypes.array,
  labelId: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  control: PropTypes.object,
  required: PropTypes.bool,
};

export default UpdateMerchantCustomSelect;


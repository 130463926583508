import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Header } from '../../components';

const PrivateRoute = () => {
  const userData = localStorage.getItem('user');
  const location = useLocation();

  return userData && userData !== null ? (
    <React.Fragment>
      <Header>
        <Outlet />
      </Header>
    </React.Fragment>
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
};

export default PrivateRoute;

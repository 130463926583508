import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

const CustomSelect = ({ inputLabel, label, items, labelId, id, value, onChange }) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl>
        <InputLabel id={labelId}>{inputLabel}</InputLabel>
        <Select 
        style={{height:'25px',
        backgroundColor: value === 'Open' ? '#4BB543 ' : '#ED4337',
        color: 'white'
        }}
          labelId={labelId} id={id} value={value} label={label} onChange={onChange}>
          {items?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

CustomSelect.propTypes = {
  inputLabel: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  items: PropTypes.array,
  labelId: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  control: PropTypes.any,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default CustomSelect;

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Download } from '@mui/icons-material';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import CustomSelect from '../CustomSelect/index';
import moment from 'moment-timezone';

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'dateOpened') {
    return dateSorting(a, b, orderBy);
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function dateSorting(a, b, orderBy) {
  let aDate = new Date(a[orderBy]);
  let bDate = new Date(b[orderBy]);
  if (bDate < aDate) {
    return -1;
  }
  if (bDate > aDate) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells, enableActions } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ color: 'white', backgroundColor: 'white' }}>
      <TableRow>
        {headCells.map((headCell) =>
          headCell.show || enableActions ? (
            <TableCell
              key={headCell.id}
              align={headCell.id === 'name' || headCell.id === 'note' ? 'left' : 'center'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ color: '#0000008A' }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            ''
          ),
        )}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable(props) {
  const {
    headCells,
    rows,
    enableActions = true,
    openModal,
    viewNotesDetail,
    type,
    onChangeStatus,
    page,
    setPage,
  } = props;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeStatusDropdown = (event, row) => {
    onChangeStatus(event.target.value, row);
  };

  const currencyFormat = (num) => {
    return (
      '$' +
      Number(num)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    );
  };

  // eslint-disable-next-line no-unused-vars
  const renderDate = (date) => {
    if (date.includes('T')) {
      const splitDate = date.split('T');
      return splitDate[0] + ' ' + splitDate[1].replace('Z', '');
    } else return date;
  };

  const renderDateNotes = (date) => {
    if (date.includes('T')) {
      const splitDate = date.split('T');
      const dateString = splitDate[0];
      const timeString = splitDate[1].replace('Z', '');

      const centralDate = moment.utc(dateString + 'T' + timeString).tz('America/Chicago');

      const formattedDate = centralDate.format('MM/DD/YYYY');
      const formattedTime = centralDate.format('hh:mm:ss A');

      return `${formattedDate} ${formattedTime}`;
    } else {
      return date;
    }
  };

  const renderTableData = (type, row) => {
    if (type === 'merchant') {
      return (
        <TableRow hover tabIndex={-1} key={row.merchantID}>
          <TableCell align='center'>{row.dBAName}</TableCell>
          <TableCell align='center'>{row.merchantID}</TableCell>
          <TableCell align='center'>{row.legalAddressLine1}</TableCell>
          <TableCell align='center'>{row.federalTaxID}</TableCell>
          <TableCell align='center'>{row.agentName[0]?.name || row.salesAgent}</TableCell>
          {/* <TableCell align='center'>{row.status}</TableCell> */}
          <TableCell align='center'>
            <CustomSelect
              inputLabel=''
              label=''
              labelId='status-custom-select-label'
              id='status-custom-select'
              value={row.status || 'Open'}
              name='status'
              items={[
                { label: 'Open', value: 'Open' },
                { label: 'Closed', value: 'Closed' },
                { label: 'Closed', value: 'D - Delete' },
              ]}
              onChange={(event) => onChangeStatusDropdown(event, row)}
            />
          </TableCell>
          <TableCell align='center'>{row.dateOpened}</TableCell>
          {enableActions ? (
            <TableCell align='center'>
              <ChecklistRtlIcon
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  viewNotesDetail(row);
                }}
              />{' '}
            </TableCell>
          ) : (
            ''
          )}
        </TableRow>
      );
    } else if (type === 'notes') {
      return (
        <TableRow hover tabIndex={-1} key={row.merchantID}>
          <TableCell
            style={{ cursor: 'pointer', overflow: 'hidden' }}
            align='center'
            onClick={() => {
              openModal && openModal(row.note, row.date, row.typeNote, row._id);
            }}
          >
            <div
              style={{
                display: 'block',
                width: '250px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textAlign: 'left',
              }}
            >
              {row?.note?.substring(0, 60) + (row?.note?.length > 60 ? '...' : '')}
            </div>
          </TableCell>
          <TableCell align='center'>{renderDateNotes(row.date)}</TableCell>
          <TableCell align='center'>{row.username}</TableCell>
        </TableRow>
      );
    } else if (type === 'reserves') {
      console.log('row: ', row);
      return (
        <TableRow hover tabIndex={-1} key={row.merchantID}>
          <TableCell align='center'>{renderDateNotes(row.activityDate)}</TableCell>
          <TableCell align='center'>{renderDepartmentType(row.departmentName)}</TableCell>
          <TableCell align='center'>{renderRecordType(row.recordType)}</TableCell>
          <TableCell align='center'>{currencyFormat(row['rvs/RelAmt'])}</TableCell>
          <TableCell align='center'>{row['rvs/RelPerc'] + '%'}</TableCell>
          <TableCell align='center'>{currencyFormat(row.grossSalesAmt)}</TableCell>
          <TableCell align='center'>{currencyFormat(row.netACHAmt)}</TableCell>
          <TableCell align='center'>{currencyFormat(row.activityAmt)}</TableCell>
          <TableCell align='center'>{currencyFormat(row.reserveBalance)}</TableCell>
        </TableRow>
      );
    } else if (type === 'pdf') {
      return (
        <TableRow>
          <TableCell align='center'>
            <div
              style={{
                display: 'block',
                width: '250px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textAlign: 'left',
              }}
            >
              {row?.fileName?.substring(0, 60) + (row?.note?.length > 60 ? '...' : '')}
            </div>
          </TableCell>
          <TableCell align='center'>{renderDateNotes(row.createdAt)}</TableCell>
          <TableCell align='center'>{row.username}</TableCell>
          <TableCell align='center'>
            <>
              <a
                // eslint-disable-next-line no-undef
                href={`${process.env.REACT_APP_SAMPLE_FILES_BUCKET}${row.key}`}
                rel='noreferrer'
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <Download style={{ cursor: 'pointer', marginLeft: 10 }}></Download>
              </a>
            </>
          </TableCell>
        </TableRow>
      );
    }
  };

  const renderDepartmentType = (departmentName) => {
    if (departmentName === '000') {
      return 'Reserves';
    } else return 'Reserves';
  };

  const renderRecordType = (recordType) => {
    if (recordType === 'B') {
      return 'Calculated Reserve';
    } else if (recordType === 'C') {
      return 'Calculated Release';
    } else if (recordType === '3') {
      return 'One Time Reserve';
    } else if (recordType === '4') {
      return 'One Time Release';
    } else if (recordType === '5') {
      return ' Record Adjustment Reserve';
    } else if (recordType === '6') {
      return ' Record Adjustment Release';
    } else if (recordType === '7') {
      return 'Transfer Funds on Reserve';
    } else return 'Calculated Reserve';
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, height: '100%' }}>
        <TableContainer sx={{ maxHeight: 800 }}>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              rows={rows}
              enableActions={enableActions}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return renderTableData(type, row);
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[8, 15, 25]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

EnhancedTable.propTypes = {
  headCells: PropTypes.array,
  rows: PropTypes.array,
  enableActions: PropTypes.bool,
  openModal: PropTypes.func,
  viewNotesDetail: PropTypes.func,
  type: PropTypes.string,
  onChangeStatus: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
};

EnhancedTableHead.propTypes = {
  order: PropTypes.bool,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.any,
  headCells: PropTypes.array,
  enableActions: PropTypes.bool,
};

import { api } from '../utils/api';

export const loginUser = async (data) =>
  await api
    .post('auth/login', data, { headers: { Authorization: 'Basic MTAxOg==' } })
    .then((response) => response);

export const logoutUser = async (data) =>
  await api
    .post('/logout', data, { headers: { Authorization: 'Basic MTAxOg==' } })
    .then((response) => response);

import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  styled,
  TextField,
  Button,
  CircularProgress,
  Box,
  Grid,
  Snackbar,
  Slide,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { addMerchant, addPDFRecord, updateMerchant, UploadPDF } from '../../services/merchant';
import { CustomUploader } from '../../components';
import UpdateMerchantCustomSelect from '../../components/UpdateMerchantCustomSelect';

const Wrapper = styled('div')({
  padding: 35,
  fontSize: 'calc(10px + 2vmin)',
});
const MerchantFormWrapper = styled('div')((props) => ({
  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
const Form = styled('form')({
  padding: 10,
  width: '100%',
});
const AddMerchant = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [severity, setSeverity] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState({});
  const inputRef = useRef(null);

  const user = JSON.parse(localStorage.getItem('user'));

  const { state } = useLocation();
  const merchant = state?.merchant;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onChangeHandler = (event) => {
    setSelectedPdf(event.target.files);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      let response;

      if (merchant === null || merchant === undefined) {
        data = { ...data, status: 'Open', dateOpened: getCurrentDate() };
        response = await addMerchant(data);

        if (response.data.success) {
          if (selectedPdf.length > 0) {
            const pdfData = new FormData();
            for (const file of selectedPdf) {
              pdfData.append('file', file);
            }
            const uploadPdfResponse = await UploadPDF(pdfData);

            if (uploadPdfResponse.data.success) {
              for (const item of uploadPdfResponse.data.result) {
                const addPDFRecordPayload = {
                  merchantID: response.data.result.merchantID,
                  fileName: item.originalname,
                  key: item.key,
                  username: user.firstName + ' ' + user.lastName,
                };
                await addPDFRecord(addPDFRecordPayload);
              }
            } else {
              setSeverity('error');
              setAlert(true);
              setAlertContent('Something went wrong while saving attachments!');
            }
          }
        } else {
          setSeverity('error');
          setAlert(true);
          setAlertContent('Something went wrong while creating new merchant!');
        }
      } else {
        response = await updateMerchant(data, merchant?._id);
      }
      setIsLoading(false);
      setAlert(true);
      setAlertContent(response.data.message);
      if (!response.data.success) {
        setSeverity('error');
        scrollTo(0, 0);
      } else {
        setSeverity('success');
        setAlert(true);
        setAlertContent('Merchant Added Successfully');
        navigate(-1);
      }
    } catch (e) {
      setIsLoading(false);
      setAlert(true);
      setAlertContent('Something went wrong!');
    }
  };

  const getCurrentDate = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    return today;
  };

  return (
    <Wrapper className='App'>
      {alert ? (
        <Snackbar
          open={alert}
          autoHideDuration={6000}
          onClose={() => {
            setAlert(false);
          }}
          TransitionComponent={Slide}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlert(false);
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertContent}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}

      <MerchantFormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 2,
              fontWeight: '600',
            }}
          >
            {merchant === null || merchant === undefined ? 'Add' : 'Update'} Merchant / DBA
            {merchant != null || merchant != undefined ? (
              <UpdateMerchantCustomSelect
                inputLabel=''
                label=''
                labelId='status-custom-select-label'
                id='status-custom-select'
                value={merchant?.status || 'Open'}
                name='status'
                control={control}
                // required
                items={[
                  { label: 'Open', value: 'Open' },
                  { label: 'Closed', value: 'Closed' },
                  { label: 'D - Delete', value: 'D - Delete' },
                ]}
              />
            ) : (
              ''
            )}
          </Box>
          <Grid item container xs={12} sm={12} style={{ padding: '30px 4px' }}>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='dBAName'
                label='DBA*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('dBAName', { required: true })}
                type='text'
                error={!!errors?.dBAName}
                helperText={`${errors?.dBAName ? 'DBA is required' : ''}`}
                defaultValue={merchant?.dBAName}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='primaryOwnerName'
                label='Contact Name*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('primaryOwnerName', { required: true })}
                type='text'
                error={!!errors?.primaryOwnerName}
                helperText={`${errors?.primaryOwnerName ? 'Contact Name is required' : ''}`}
                defaultValue={merchant?.primaryOwnerName}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='physicalAddressLine2'
                label='Address*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('physicalAddressLine2', { required: true })}
                type='text'
                error={!!errors?.physicalAddressLine2}
                helperText={`${errors?.physicalAddressLine2 ? 'Address is required' : ''}`}
                defaultValue={merchant?.physicalAddressLine2}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='physicalAddressLine1'
                label='Address'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('physicalAddressLine1')}
                type='text'
                defaultValue={merchant?.physicalAddressLine1}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='physicalZip'
                label='Zip*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('physicalZip', { required: true })}
                type='text'
                error={!!errors?.physicalZip}
                helperText={`${errors?.physicalZip ? 'Zip is required' : ''}`}
                defaultValue={merchant?.physicalZip}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='physicalCity'
                label='City*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('physicalCity', { required: true })}
                type='text'
                error={!!errors?.physicalCity}
                helperText={`${errors?.physicalCity ? 'City is required' : ''}`}
                defaultValue={merchant?.physicalCity}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='physicalState'
                label='State*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('physicalState', { required: true })}
                type='text'
                error={!!errors?.physicalState}
                helperText={`${errors?.physicalState ? 'State is required' : ''}`}
                defaultValue={merchant?.physicalState}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='merchantID'
                label='MID*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('merchantID', { required: true })}
                type='text'
                error={!!errors?.merchantID}
                helperText={`${errors?.merchantID ? 'MID is required' : ''}`}
                defaultValue={merchant?.merchantID}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='phone'
                label='Phone*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('phone', { required: true })}
                type='number'
                error={!!errors?.phone}
                helperText={`${errors?.phone ? 'Phone is required' : ''}`}
                defaultValue={merchant?.phone}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='merchantEmailAddress'
                label='Email*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('merchantEmailAddress', { required: true })}
                type='email'
                error={!!errors?.merchantEmailAddress}
                helperText={`${errors?.merchantEmailAddress ? 'Email is required' : ''}`}
                defaultValue={merchant?.merchantEmailAddress}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='salesAgent'
                label='Sales Agent*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('salesAgent', { required: true })}
                type='text'
                error={!!errors?.salesAgent}
                helperText={`${errors?.salesAgent ? 'Sales Agent is required' : ''}`}
                defaultValue={merchant?.salesAgent}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='salesOffice'
                label='Sales Office*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('salesOffice', { required: true })}
                type='text'
                error={!!errors?.salesOffice}
                helperText={`${errors?.salesOffice ? 'Sales Office is required' : ''}`}
                defaultValue={merchant?.salesOffice}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 2,
              fontWeight: '600',
            }}
          >
            {merchant === null || merchant === undefined ? 'Add' : 'Update'} Merchant / Legal
          </Box>

          <Grid item container xs={12} sm={12} style={{ padding: '30px 4px' }}>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='legalAddressLine1'
                label='Legal Name*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('legalAddressLine1', { required: true })}
                type='text'
                error={!!errors?.legalAddressLine1}
                helperText={`${errors?.legalAddressLine1 ? 'Legal Name is required' : ''}`}
                defaultValue={merchant?.legalAddressLine1}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='federalTaxID'
                label='EIN*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('federalTaxID', { required: true })}
                type='text'
                error={!!errors?.federalTaxID}
                helperText={`${errors?.federalTaxID ? 'EIN is required' : ''}`}
                defaultValue={merchant?.federalTaxID}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='legalAddressLine2'
                label='Address'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('legalAddressLine2')}
                type='text'
                defaultValue={merchant?.legalAddressLine2}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='legalZip'
                label='Zip*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('legalZip', { required: true })}
                type='text'
                error={!!errors?.legalZip}
                helperText={`${errors?.legalZip ? 'Zip is required' : ''}`}
                defaultValue={merchant?.legalZip}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='legalCity'
                label='City*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('legalCity', { required: true })}
                type='text'
                error={!!errors?.legalCity}
                helperText={`${errors?.legalCity ? 'City is required' : ''}`}
                defaultValue={merchant?.legalCity}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='legalState'
                label='State*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('legalState', { required: true })}
                type='text'
                error={!!errors?.legalState}
                helperText={`${errors?.legalState ? 'State is required' : ''}`}
                defaultValue={merchant?.legalState}
              />
            </Grid>
            {(merchant === null || merchant === undefined) && (
              <CustomUploader
                onChangeHandler={onChangeHandler}
                inputRef={inputRef}
                isPdfUpload={true}
              />
            )}
          </Grid>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flexDirection: 'row',
            }}
          >
            <Button
              variant='contained'
              sx={{
                width: '20%',
                height: 55,
              }}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button
              type='submit'
              variant='contained'
              sx={{
                width: '20%',
                height: 55,
                marginLeft: 2,
              }}
            >
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : merchant === null || merchant === undefined ? (
                'Save'
              ) : (
                'Update'
              )}
            </Button>
          </div>
        </Form>
      </MerchantFormWrapper>
    </Wrapper>
  );
};

export default AddMerchant;

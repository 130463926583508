import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, styled } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { formatPhoneNumber } from '../../../utils/Functions/commonFunctions';
import './index.css';

const Wrapper = styled('div')({
  padding: 15,
  border: '1px solid #ccc',
  borderRadius: '16px',
  marginTop: '70px',
});

const MerchantDetails = ({ merchant, isLoading }) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      {isLoading ? (
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation='wave' />
          <Skeleton animation={false} />
        </Box>
      ) : (
        <div style={{ fontSize: '18px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/addMerchant', { state: { merchant } })}
            >
              <Edit />
            </IconButton>
          </div>

          <p className='DBA-font'>
            <b>{merchant && merchant.dBAName ? merchant.dBAName : ''}</b>
          </p>
          <p className='label-font'>
            <b>MID: </b>
            {merchant && merchant.merchantID ? merchant.merchantID : ''}
          </p>
          <p className='label-font'>
            <b>Status: </b>
            {merchant && merchant.status ? merchant.status : ''}
          </p>
          {merchant && merchant.physicalAddressLine2 ? (
            <p className='label-font'>
              <b>DBA Address: </b>
              {merchant.physicalAddressLine2}
            </p>
          ) : (
            <></>
          )}
          <p className='label-font'>
            <b>Contact:</b> {merchant && merchant.primaryOwnerName ? merchant.primaryOwnerName : ''}{' '}
            {'  '}
            {merchant && merchant.phone ? formatPhoneNumber(merchant.phone) : ''}
          </p>
          <p className='label-font'>
            <b>Email:</b>{' '}
            {merchant && merchant.merchantEmailAddress ? merchant.merchantEmailAddress : ''}
          </p>
          {(merchant && merchant.agentName[0]?.name) || merchant.salesAgent ? (
            <p className='label-font'>
              <b>Sales Agent:</b> {merchant.agentName[0]?.name || merchant.salesAgent}
            </p>
          ) : (
            <></>
          )}
          {merchant && merchant.salesOffice ? (
            <p className='label-font'>
              <b>Sales Office:</b> {merchant.salesOffice}
            </p>
          ) : (
            <></>
          )}
          {merchant && merchant.legalAddressLine1 ? (
            <p className='label-font'>
              <b>Legal Name:</b> {merchant.legalAddressLine1}{' '}
            </p>
          ) : (
            <></>
          )}
          {merchant && merchant.federalTaxID ? (
            <p className='label-font'>
              <b>EIN:</b> {merchant.federalTaxID}
            </p>
          ) : (
            <></>
          )}
          {merchant && merchant.legalAddressLine2 ? (
            <p className='label-font'>
              <b>Legal Address: </b>
              {merchant.legalAddressLine2}
            </p>
          ) : (
            <></>
          )}
        </div>
      )}
    </Wrapper>
  );
};

MerchantDetails.propTypes = {
  merchant: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default MerchantDetails;

import axios from 'axios';

let userData = localStorage.getItem('user');
userData = JSON.parse(userData);

export const api = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  // timeout: 1000,
});

const exemptedUrls = ['/login', '/logout'];

api.interceptors.request.use((request) => {
  if (!exemptedUrls.includes(request?.url)) {
    if (userData !== null) {
      request.headers.Authorization = `${userData?.token}`;
    }
  }
  return request;
});

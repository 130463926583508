import { Box, Typography } from '@mui/material';
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // For debugging
    this.setState({ error, errorInfo });
  }
  render() {
    // Error path
    if (this.state.hasError) {
      return (
        <div>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            minHeight='60vh'
          >
            <Typography variant='h3'>
              <div>Oops!!! Something went wrong</div>
            </Typography>
          </Box>
        </div>
      );
    }

    // Normally, just render children
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default ErrorBoundary;

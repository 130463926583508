import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Skeleton } from '@mui/material';
// import { Search } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { EnhancedTable } from '../../../components';
import { headCellsMerchants } from '../../../utils/tableData';
import { getAllMerchants, updateMerchant } from '../../../services/merchant';
import './Merchants.css';

const Merchants = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [merchantsCopy, setMerchantsCopy] = useState(merchants);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [severity, setSeverity] = useState('');
  const [page, setPage] = useState(0);
  const [size] = useState(10);
  const [search, setSearch] = useState('');

  const requestSearch = (searched) => {
    setMerchantsCopy(
      merchants.filter(
        (item) =>
          item?.merchantID?.toString().indexOf(searched) > -1 ||
          item?.federalTaxID?.toString().indexOf(searched) > -1 ||
          item?.dBAName?.toString().toLowerCase().indexOf(searched.toLowerCase()) > -1 ||
          item?.legalAddressLine1?.toString().toLowerCase().indexOf(searched.toLowerCase()) > -1 ||
          item?.agentName[0]?.name?.toString().toLowerCase().indexOf(searched.toLowerCase()) > -1 ||
          item?.salesAgent?.toString().toLowerCase().indexOf(searched.toLowerCase()) > -1,
      ),
    );
    setPage(0);
  };
  useEffect(() => {
    getAllMerchantsHandler();
  }, []);

  const getAllMerchantsHandler = async () => {
    setIsLoading(true);
    const payload = { page, size, search };
    const response = await getAllMerchants(payload);
    if (response.data.success) {
      setMerchants(response.data.result);
      setMerchantsCopy(response.data.result);
    }
    setIsLoading(false);
  };

  const viewNotesDetail = (notes) => {
    navigate('notes', {
      state: {
        MID: notes._id,
      },
    });
  };

  const onChangeStatus = (value, row) => {
    let merchants = merchantsCopy;
    var foundIndex = merchants.findIndex((x) => x.merchantID == row.merchantID);
    merchants[foundIndex].status = value;
    setMerchantsCopy([...merchants]);
    updateMerchanthandler(merchants[foundIndex]);
  };

  const updateMerchanthandler = async (merchant) => {
    const response = await updateMerchant(merchant, merchant._id);
    if (response.data.success) {
      setSeverity('success');
    } else {
      setSeverity('error');
    }
    setAlert(true);
    setAlertContent(response.data.message);
  };

  return (
    <div>
      {alert ? (
        <Alert
          severity={severity || 'error'}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setAlert(false);
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {alertContent}
        </Alert>
      ) : (
        <></>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 2,
        }}
      >
        <TextField
          label='Search by DBA, MID, Legal, EIN, or Agent...'
          variant='outlined'
          sx={{ width: '100%', backgroundColor: 'white' }}
          type='search'
          onInput={(e) => {
            setSearch(e.target.value);
            requestSearch(e.target.value);
          }}
        />
        {/* <Button variant='outlined' sx={{ height: 55, background: '#fff' }}>
          <Search /> Search
        </Button> */}
      </Box>

      <Box
        sx={{
          marginTop: 2,
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isLoading ? (
          <>
            <Skeleton variant='rectangular' height={'20vh'} />
            <Skeleton variant='rectangular' height={'20vh'} sx={{ marginTop: 1 }} />
            <Skeleton variant='rectangular' height={'20vh'} sx={{ marginTop: 1 }} />
          </>
        ) : (
          <EnhancedTable
            headCells={headCellsMerchants}
            rows={merchantsCopy}
            enableActions={true}
            viewNotesDetail={viewNotesDetail}
            type={'merchant'}
            onChangeStatus={onChangeStatus}
            page = {page}
            setPage = {setPage}
          />
        )}
      </Box>
    </div>
  );
};

export default Merchants;

import * as React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormControl } from '@mui/material';

const DateSelector = ({ label, error, required, control, name }) => {
  return (
    <FormControl fullWidth size='small'>
      <Controller
        rules={{ required: required }}
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={label}
                value={value}
                className='w-90'
                onChange={(newValue) => {
                  onChange(dayjs(newValue).format());
                }}
                renderInput={(params) => <TextField {...params} error={error} />}
              />
            </LocalizationProvider>
          );
        }}
      />
    </FormControl>
  );
};

DateSelector.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  control: PropTypes.any,
  name: PropTypes.string,
};
export default DateSelector;

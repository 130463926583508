import { api } from '../utils/api';

export const addNotes = async (data) =>
  await api
    .post('notes/add', data, { headers: { Authorization: 'Basic MTAxOg==' } })
    .then((response) => response);

export const editNotes = async (data) =>
  await api
    .put(`notes/${data.id}`, data, {
      headers: { Authorization: 'Basic MTAxOg==' },
    })
    .then((response) => response);

import React from 'react';
import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from '../layouts';
import { ErrorBoundary } from '../views';

const Login = lazy(() => import('../views/Login'));
const Home = lazy(() => import('../views/Home'));
const Notes = lazy(() => import('../views/Notes'));
const AddMerchant = lazy(() => import('../views/AddMerchant'));

const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            height: '100%',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        ></div>
      }
    >
      <Routes>
        {/* Public Routes */}
        <Route path='/login' element={<Login />} />

        {/* Private Routes */}
        <Route element={<PrivateRoute allowedRoles={[1]} />}>
          <Route path='/' element={<Home />} />
          <Route path='/notes' element={<Notes />} />
          <Route path='/addMerchant' element={<AddMerchant />} />
        </Route>

        {/* Catch Route */}
        <Route path='*' element={<ErrorBoundary />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;

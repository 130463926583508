import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { styled, TextField, Button, CircularProgress, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { addReserve } from '../../../services/reserve';
import { DateSelector } from '../../../components';
import dayjs from 'dayjs';

const Wrapper = styled('div')({
  padding: 35,
  fontSize: 'calc(10px + 2vmin)',
});
const ReserveFormWrapper = styled('div')((props) => ({
  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
const Form = styled('form')({
  padding: 10,
  width: '100%',
});

const ReserveNotes = ({ handleModalClose, mID }) => {
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [severity, setSeverity] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control: reserveControl,
    formState: { errors },
  } = useForm({
    defaultValues: {
      activityDate: dayjs().format(),
      departmentName: '',
      recordType: '',
      'rvs/RelAmt': '',
      'rvs/RelPerc': '',
      grossSalesAmt: '',
      netACHAmt: '',
      activityAmt: '',
      reserveBalance: '',
    },
  });

  const onSubmit = async (data) => {
    try {
      data = { ...data, mID: mID };
      setIsLoading(true);
      const response = await addReserve(data);
      setIsLoading(false);
      setAlert(true);
      setAlertContent(response.data.message);
      if (!response.data.success) {
        setSeverity('error');
      } else {
        setSeverity('success');
        handleModalClose('success');
      }
    } catch (e) {
      setIsLoading(false);
      setAlert(true);
      setSeverity('error');
      setAlertContent('Something went wrong!');
    }
  };
  return (
    <Wrapper className='App'>
      {alert ? (
        <Alert
          severity={severity || 'error'}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setAlert(false);
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {alertContent}
        </Alert>
      ) : (
        <></>
      )}

      <ReserveFormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid item container xs={12} sm={12} style={{ padding: '30px 4px' }}>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <DateSelector
                name='activityDate'
                required={true}
                label='Activity Date*'
                control={reserveControl}
                error={!!errors?.activityDate}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='departmentName'
                label='Department Name*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('departmentName', { required: true })}
                type='text'
                error={!!errors?.departmentName}
                helperText={`${errors?.departmentName ? 'Department Name is required' : ''}`}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='recordType'
                label='Record Type*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('recordType', { required: true })}
                type='text'
                error={!!errors?.recordType}
                helperText={`${errors?.recordType ? 'Record Type is required' : ''}`}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='rvs/RelAmt'
                label='rvs/RelAmt*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('rvs/RelAmt', { required: true })}
                type='number'
                error={!!errors?.['rvs/RelAmt']}
                helperText={`${errors?.relAmt ? 'rvs/RelAmt is required' : ''}`}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='rvs/RelPerc'
                label='rvs/RelPerc*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('rvs/RelPerc', { required: true })}
                type='number'
                error={!!errors?.['rvs/RelPerc']}
                helperText={`${errors?.relPerc ? 'rvs/RelPerc is required' : ''}`}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='grossSalesAmt'
                label='Gross Sales Amt*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('grossSalesAmt', { required: true })}
                type='number'
                error={!!errors?.grossSalesAmt}
                helperText={`${errors?.grossSalesAmt ? 'Gross Sales Amt is required' : ''}`}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='netACHAmt'
                label='Net ACH Amt*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('netACHAmt', { required: true })}
                type='text'
                error={!!errors?.netACHAmt}
                helperText={`${errors?.netACHAmt ? 'Net ACH Amt is required' : ''}`}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='activityAmt'
                label='Activity Amt*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('activityAmt', { required: true })}
                type='text'
                error={!!errors?.activityAmt}
                helperText={`${errors?.activityAmt ? 'Activity Amt is required' : ''}`}
              />
            </Grid>
            <Grid item xs={6} sm={6} sx={{ padding: 2 }}>
              <TextField
                id='reserveBalance'
                label='Reserve Balance*'
                variant='outlined'
                sx={{ width: '100%' }}
                {...register('reserveBalance', { required: true })}
                type='number'
                error={!!errors?.reserveBalance}
                helperText={`${errors?.reserveBalance ? 'Reserve Balance is required' : ''}`}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Button
                type='button'
                variant='contained'
                sx={{
                  margin: 1,
                  width: '40%',
                  height: 55,
                  marginLeft: 'auto',
                  display: 'block',
                }}
                onClick={() => handleModalClose('success')}
              >
                Close
              </Button>
            </Grid>
            <Grid item xs={6}>
              {' '}
              <Button
                type='submit'
                variant='contained'
                sx={{
                  margin: 1,
                  width: '40%',
                  height: 55,
                  marginLeft: 'auto',
                  display: 'block',
                }}
              >
                {isLoading ? <CircularProgress style={{ color: 'white' }} /> : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </ReserveFormWrapper>
    </Wrapper>
  );
};

ReserveNotes.propTypes = {
  handleModalClose: PropTypes.func,
  mID: PropTypes.string,
};

export default ReserveNotes;

import { api } from '../utils/api';

export const importMerchants = async (data) =>
  await api
    .post('import', data, { headers: { Authorization: 'Basic MTAxOg==' } })
    .then((response) => response);

export const UploadPDF = async (data) =>
  await api.post('upload', data).then((response) => response);

export const addPDFRecord = async (data) =>
  await api.post('merchantPdf/add', data).then((response) => response);

export const getAllMerchants = async (data) => {
  const { page, size, search } = data;
  return await api
    .get(`merchant/all?page=${page}&size=${size}&search=${search}`)
    .then((response) => response);
};

export const addMerchant = async (data) =>
  await api
    .post('merchant/add', data, {
      headers: { Authorization: 'Basic MTAxOg==' },
    })
    .then((response) => response);

export const updateMerchant = async (data, id) =>
  await api.put(`merchant/${id}`, data).then((response) => response);

export const getMerchantById = async (id) =>
  await api
    .get(`merchant/get/${id}`, {
      headers: { Authorization: 'Basic MTAxOg==' },
    })
    .then((response) => response);
